<template>
<base-layout>
    
    <ion-content>
         <div v-if="!isDesktop" class="mobile-meeting-info">
          <div class="mobile-title"><b>{{appointmentById.subject}}</b></div>
          <div class="mobile-time">{{start}}<b> - </b>{{end}}</div>     
        </div>

          <div class="mobile-container" v-if="!isDesktop">
           <div  v-for="(item, key) in streamList" :key="key" style="z-index: 0">
             <div  class="mobile-big"  v-if="item.srcObject.type!='local'" >
            <video class="big-video"
                    
                    autoplay
                    playsinline
                    :srcObject.prop="item.srcObject" 
                    :muted="item.muted"
                    :id="item.id"
            >
            </video>
            </div>

            <div class="mobile-small" v-if="item.srcObject.type=='local'" > 
              <video class="small-video"
                    
                      autoplay
                      playsinline
                      :srcObject.prop="item.srcObject" 
                      :muted="item.muted"
                      :id="item.id"
              >
              </video>
                <div class="muted-icon">
               <ion-icon  v-if="audioMute" class="mobile-muted" :icon="micOff"></ion-icon>
            </div>
        </div>
            
        </div>
         
         
      <div class="mobile-info-container" v-if="Object.keys(streamList).length<2">
        <div class="video-item info-wrapper">
                <div class="info" v-if="Object.keys(streamList).length!=0"> <ion-spinner class="spinner" color="white" name="lines"></ion-spinner> <br> <div style="margin-top: 20px;"> Waiting for <b v-if="usersSet"> {{remote.firstName}} {{remote.lastName}} </b> </div> </div>
          
        </div>
    </div>

    <div class="mobile-info-container mobile-local-info-container" v-if="Object.keys(streamList).length<2">
        <div class="video-item info-wrapper">
          
                <div class="info" v-if="Object.keys(streamList).length==0"><i> <ion-spinner class="small-spinner" color="white" name="lines"> </ion-spinner> </i> <span class="mobile-nocamera"> No camera detected </span> </div>
        </div>
    </div>

          <div class="mobile-buttons">
             <ion-button class="tabs tabs-positive mobile-mute" color="light" v-if="!audioMute" @click="muteUser()"> <ion-icon :icon="micOff"></ion-icon> </ion-button>
             <ion-button class="tabs tabs-positive mobile-mute" color="light" v-if="audioMute" @click="unmuteUser()"> <ion-icon :icon="mic"></ion-icon>  </ion-button>
             <router-link class="mobile-mute" tag="button" :to="'/app/dashboard'" v-on:click="leaveChannel()"> <ion-button class="tabs tabs-positive " color="danger" >END CALL <ion-icon class="hang-icon" :icon="call"></ion-icon>  </ion-button> </router-link>

             </div>
        
        </div>


      <!-- DESKTOP -->
       <div class="stage" v-if="isDesktop">
        <div  class="meeting-info video-mobile-deactivated">
            <div class="conference-title-container">
            <div class="title"> <b>{{appointmentById.location}}</b> <span v-if="conferenceReady" class="title-description">- {{conferenceById.description}} </span></div>
          </div>

          <div class="title"><b>{{appointmentById.subject}}</b></div>
          <div class="time">{{start}}<b> - </b>{{end}}</div>
          <div class="time" v-if="usersSet"> {{localUser.firstName}} {{localUser.lastName}}  <b> - </b> {{remote.firstName}} {{remote.lastName}} </div>
    
        
        </div>
        
        <div class="background-no-camera" v-if="Object.keys(streamList).length==0"> </div>
        

          
    <div id="video-wrapper" class="video-wrapper">
       
    <div v-if="Object.keys(streamList).length<1"></div>
    
    <div class="info-container" v-if="Object.keys(streamList).length<2">
      <div class="video-item info-wrapper">
              <div class="info" v-if="Object.keys(streamList).length!=0"> <ion-spinner class="spinner" color="white" name="lines"></ion-spinner> <br> <div class="waiting-text"> Waiting for <b v-if="usersSet"> {{remote.firstName}} {{remote.lastName}} </b> </div> </div>
              <div class="info" v-if="Object.keys(streamList).length==0">No camera detected</div>
      </div>
    
    
    </div>
        <div class="video-item video-item-remote" v-for="(item, key) in streamList" :key="key" style="z-index: 0">
            <video class="video-item format-remote"
                     v-if="item.srcObject.type!='local'"
                    autoplay
                    playsinline
                    :srcObject.prop="item.srcObject" 
                    :muted="item.muted"
                    :id="item.id"
            >
            </video>
            
        </div>
        
    <div v-for="(item, key) in streamList" :key="key">
      <div class="video-item video-item-me" v-if="item.srcObject.type=='local'" > 
            <video class="video-item format-me"
                   
                    autoplay
                    playsinline
                    :srcObject.prop="item.srcObject" 
                    :muted="item.muted"
                    :id="item.id"
            >
            </video>

            <div class="muted-icon">
               <ion-icon v-if="audioMute" class="muted-icon-item" :icon="micOff"></ion-icon>
            </div>

            <div class="videoroom-button-section"  v-if="item.srcObject.type=='local'" style="text-align: center; justify-content: center;  align-items: center;">
             <ion-button class="tabs tabs-positive mute-button" color="light" v-if="!audioMute" @click="muteUser()"> <ion-icon :icon="micOff"></ion-icon> </ion-button>
             <ion-button class="tabs tabs-positive mute-button" color="light" v-if="audioMute" @click="unmuteUser()"> <ion-icon :icon="mic"></ion-icon>  </ion-button>
             <router-link class="mute-button" tag="button" :to="'/app/dashboard'" v-on:click="leaveChannel()"> <ion-button class="tabs tabs-positive " color="danger" >END CALL <ion-icon class="hang-icon" :icon="call"></ion-icon>  </ion-button> </router-link>

             </div>
        </div>
        </div>

     
        
        <template v-if="Object.keys(streamList).length==0">
         <div class="video-item video-item-me no-camera" style="">
            <div class="video-item info-wrapper">
              <ion-spinner class="spinner" color="white" name="lines"></ion-spinner>
              <br>
              <br>
              <div class="info"> <b> No camera detected </b> </div>
            </div>
        </div>
        </template>
    </div>
   </div>
    </ion-content>

  </base-layout>
</template>


<script>
import {micOff} from 'ionicons/icons';
import {mic} from 'ionicons/icons';
import {call} from 'ionicons/icons';

import { isPlatform } from '@ionic/vue';
import {mapActions,mapGetters} from 'vuex';
import {defineComponent} from 'vue';
import RTCMultiConnection from 'rtcmulticonnection'
import * as io from 'socket.io-client'
window.io = io
import sound from '../../assets/audio/hang_call.mp3'


export default defineComponent({
  name: 'Videoroom',
  props: ['id'],

  setup() {
    return {
        micOff,
        call,
        mic
    }
},

  unmounted() {
    this.leaveChannel();
  },


  data() {
    return {
       isDesktop: null,
      notValid:false,
      appointmentId: this.$route.params.id,
      start: null,
      end: null,
      localUser: null,
      remote: null,
      authUser: null,
      user:null,//real one
      usersSet: false,
      connection: null,
      streamCount: null,
      loaded: null,
      roomcode: null,
      videoHide: false,
      audioMute: false,
      isOnline: false,
      localObject: null,  
      remoteObject: null,
      conferenceReady: false
      }
  },

  components: {
 
  },
  methods: {    

    isMobile(){
      return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) );
    },

    muteUser(){
      var localStream = this.connection.attachStreams[0];
      localStream.mute('audio');
      this.audioMute=true
    },    

    unmuteUser(){
       var localStream = this.connection.attachStreams[0];
      localStream.unmute('audio');
      this.audioMute=false
    }, 

    ...mapActions('appointment', ['getAppointmentById']),
    ...mapActions('conference', ['getConferenceById']),
    ...mapActions("video", ["addStream","socket_disconnectStream","resetStreamList"]),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapActions('consultant', ['getConsultantById']),
    ...mapActions('googleanalytics',['trackWithLabel']),

    dateFormat(date){
      date=date.toString().slice(11,19);
      return date;
    },

    //setting users
    setUsers(){
      console.log("authUsers")
      console.log(this.appointmentById.authUsers)
      this.localUser = this.appointmentById.authUsers.find(_user=>_user.id == this.user.id);
      this.remote = this.appointmentById.authUsers.find(_user=>_user.id != this.user.id);
      this.usersSet=true;
    },

    //leaving channel function


    async leaveChannel() {
      let leaveSound = new Audio(sound);
      leaveSound.play();

      let self = this;  
      if(self.me){
      let param = {
        conference: this.appointmentId, //gotta check this
        channel: this.me.room,
        stream_id: this.me.streamid
      };

      this.socket_disconnectStream(param);
      this.resetStreamList();
      this.connection.getAllParticipants().forEach(function (participantId) {
        self.connection.disconnectWith(participantId);
        });
      //this.muteVideo();
      let localStream = this.connection.attachStreams[0];

        if(localStream){
          localStream.getVideoTracks().forEach(function (track) {
            track.stop(); // turn off camera recording
          });
          localStream.getAudioTracks().forEach(function (track) {
            track.stop(); // turn off mic recording
          });
        }

      this.connection.closeSocket();
      }
    },
  },
  
  computed: {
   ...mapGetters('appointment',['appointmentById']),

   ...mapGetters('conference',['conferenceById']),

   ...mapGetters('consultant',['consultantById']),

   streamList: function () {
        return this.$store.getters["video/getStreamList"];
      },
   users: function () {
        let userlist = this.$store.getters["rooms/getUsers"];
        return userlist;
      }
  },

  watch: {
      "$socket.connected": function () {
        console.log("connection change", this.$socket.connected);
        if (!this.$socket.connected) {
          this.resetStreamList(this.me.streamid);
        }
      },

      '$route': {

      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(route) {
        if (route.name == 'Videoroom') {
          console.log("navigator")
          

          console.log(navigator.getUserMedia)
          if(!navigator.getUserMedia){
           alert('Your device does not support this function. Please upgrade or use a web browser')
           this.$router.push('/app/dashboard');
           }else{ 
     
     await this.getAppointmentById(this.appointmentId);
     await this.getConferenceById(this.appointmentById.conference_id);
     await this.getConsultantById(this.appointmentById.consultant_id);

     //CHECK IF THE CONNECTED USER IS SET IN THE CONFERENCE
      this.conferenceReady = true;
      this.authUser = await this.$store.dispatch('auth/GET_USERDATA');
      this.user = { "id": this.authUser.data.id } //$rootScope.user; CHANGE THIS WHEN AUTHSERVER IS ADDED
      this.start =this.dateFormat(this.appointmentById.start_at)
      this.end = this.dateFormat(this.appointmentById.end_at)
      this.setUsers();
      this.resetStreamList();
      this.roomcode = this.appointmentId
      //CHECK IF USER IS IN THE MEETING OR NOT:
      if (this.appointmentById.requester_user_id == this.authUser.data.id || this.consultantById.user_id == this.authUser.data.id  ){
      //we start the connection here
      this.connection = new RTCMultiConnection();
      //let user = localStorage.getItem("user"); //cambiar esto
      this.connection.socketURL = "https://rtcmulticonnection.herokuapp.com:443/";
      this.connection.session = {
        audio: true,
        video: {
          aspectRatio: 16/9
        }
      };
      this.connection.sdpConstraints.mandatory = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
      };

      // use your own TURN-server here!
      this.connection.iceServers = [
          {urls: 'turn:turn.documedias.com',credential: 'turnturnturn', username: 'turn'},
        {
          urls: [
            "stun:stun.l.google.com:19302",
            "stun:stun1.l.google.com:19302",
            "stun:stun2.l.google.com:19302",
            //"stun:stun.l.google.com:19302?transport=udp"
          ]
        },
      ];
      this.connection.enableLogs = false;
      this.connection.autoCreateMediaElement = false;
      let connect = this.connection;
      let self = this;

       this.connection.extra = {
        authUser: JSON.parse(localStorage.getItem("userObj"))
      };
      this.connection.socketCustomParameters =
          "&extra=" + JSON.stringify(self.connection.extra);

      self.connection.openOrJoin(self.appointmentId, function (isRoomExist) {
        if (isRoomExist === false && connect.isInitiator === true) {
          isRoomExist = false;
        }
      });

      // ON CONNECTION
      this.connection.onstream = function (event) {
        
        console.log("onsignal", event, event.extra);
       // let participant = {};
        event.room = self.roomId;
        if (event.type === "local") {
          
          self.me = event;
          console.log("me", event);
        }
        self.connection.socket.emit(
            "get-remote-user-extra-data",
            event.userid,
            function (extra) {
              let stream = {
                id: event.streamid,
                srcObject: event.stream,
                muted: event.type === "local",
                user: extra.authUser,
                room: event.room
              };
              self.addStream(stream);
            }
        );
      };

      // ON STREAM ENDED
      this.connection.onstreamended = function (event) {
        console.log("streamend", event);
        self.connection.disconnectWith(event.userid);
        delete self.streamList[event.streamid]; //changed from vue.delete to native js, need to check if this works
      };


      // ON MUTE
       this.connection.onmute = function (e) {
        console.log("onmute", e);
        if (!e || !e.mediaElement) {
          return;
        }

        if (e.muteType === "both" || e.muteType === "video") {
          e.mediaElement.src = null;
          if (e.mediaElement.pause) var paused = e.mediaElement.pause();
          if (typeof paused !== "undefined") {
            paused.then(function () {
              e.mediaElement.poster =
                  "https://cdn.webrtc-experiment.com/images/muted.png";
              //e.snapshot ||
              //"https://cdn.webrtc-experiment.com/images/muted.png";
            });
          } else {
            e.mediaElement.poster =
                "https://cdn.webrtc-experiment.com/images/muted.png";
            //e.snapshot ||
            //"https://cdn.webrtc-experiment.com/images/muted.png";
          }
        } else if (e.muteType === "audio") {
          e.mediaElement.muted = true;
        }
      };
      
      // ON UNMUTE
      this.connection.onunmute = function (e) {
        console.log("onunmute", e);
        if (!e || !e.mediaElement || !e.stream) {
          return;
        }

        if (e.unmuteType === "both" || e.unmuteType === "video") {
          e.mediaElement.poster = null;
          e.mediaElement.srcObject = e.stream;
          if (e.mediaElement.play) {
            e.mediaElement.play();
            e.mediaElement.muted = false;
          }
        } else if (e.unmuteType === "audio") {
          e.mediaElement.muted = false;
          //hacer luego aqui algo para q el otro sepa q esta muteado
        }
      };
        }else{
          alert("You are not invited to this meeting");//idk make an alert and then a redirect or smt
            this.leaveChannel()
            this.$router.push('/app/meetings');

        }
        }
        } else {
           this.leaveChannel() //leave channel if route changes but its not the same one
           
        }
        this.trackWithLabel(this.appointmentId)
      }
      
    }

    },

  

  updated(){
  },

  async created() {  
    this.isDesktop= isPlatform('desktop');

  },

});
</script>
<style lang="scss">

</style>